declare const __PIANO_ANALYTICS_SCRIPT_TAG_URL__: any;
declare const __PIANO_ANALYTICS_SCRIPT_TAG_URL_FUNCTION__: any;
// Initializer
import initializeTracker from '@/tracker/initialize-tracker';

const injectTracker = async (): Promise<void> => {
    const headElement: HTMLHeadElement | null =
        document.querySelector<HTMLHeadElement>('head');

    if (
        !headElement ||
        document.querySelector('#__PIANO_ANALYTICS_SCRIPT_TAG_ID__')
    ) {
        return;
    }

    __PIANO_ANALYTICS_SCRIPT_TAG_URL_FUNCTION__;

    const scriptElement = document.createElement('script');

    scriptElement.setAttribute('id', '__PIANO_ANALYTICS_SCRIPT_TAG_ID__');
    scriptElement.setAttribute('type', 'text/javascript');
    scriptElement.setAttribute('async', 'true');
    scriptElement.setAttribute('src', __PIANO_ANALYTICS_SCRIPT_TAG_URL__);
    scriptElement.addEventListener('load', initializeTracker as EventListener);

    await headElement.append(scriptElement);
};

export default injectTracker;
