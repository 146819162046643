
// Event Getters
import getPageDisplayEvent from "@/tracker/event-getters/get-page-display-event";
import getPageDisplayCustomEvent from "@/tracker/event-getters/get-page-display-custom-event";
// types
import {  PianoAnalytics, PageDisplayEvent, PageDisplayCustomEvent } from '@/types'

// declare var pa: PianoAnalytics;
declare var trackerTag: PianoAnalytics;

const sendPageDisplay = () : void => {

    const event: PageDisplayCustomEvent | PageDisplayEvent  = JSON.parse('__WEB_ANALYTICS_PAGE_DISPLAY_CUSTOM_EVENT__') ? getPageDisplayCustomEvent() : getPageDisplayEvent()
    // console.log('page.display', event)
    
    // pa.sendEvent('page.display', event)
    if (trackerTag) trackerTag.events.send('page.display', event);
}

export default sendPageDisplay
