// tracker injector
import injectTracker from '@/tracker/inject-tracker'
// Types
// import { PianoAnalytics } from '@/types/index'

// Piano Configuration
// declare var pa: PianoAnalytics;
// pa.setConfigurations({ 'site': '__SITE_ID__', 'collectDomain':  '__COLLECT_DOMAIN__' })

// Piano Configuration
// @ts-ignore
let trackerTag: any

if (document.readyState === 'complete') {
    injectTracker()
  } else {
    window.addEventListener('load', injectTracker);
}