


export const EVENT_ATTACHED_ATTRIBUTE_SELECTOR: string = 'data-analytics-event-attached'

export const WEB_ANALYTICS__CUSTOM_EVENTS: { [key : string]: string } = {
    EP_BODIES_SELECTOR: '#webanalytic-ep_bodies',
    TOPICS_BY_WEEKS_SELECTOR: '#webanalytics-topics_by_weeks',
    PRIORITY_CATEGORY_SELECTOR: '#webanalytics-priority_category',
    PRIORITY_WORDLINK_SELECTOR: '#webanalytics-priority_wordlink',
    INTERNAL_KEYWORD_SELECTOR: '#news_search-keyword',
    ARTICLE_LANGUAGE_SELECTOR: '#webanalytics-language',
    ARTICLE_REFERENCE_SELECTOR: '#webanalytics-reference'
}

export const LINK_TYPE_IDENTIFIERS : { [key : string]: string | any }= {
    MULTIMEDIA_CENTER: 'multimediacentre.europarl.europa.eu',
    AUDIO_VISUAL: 'audiovisual.europarl.europa.eu',
    EUROPARL_TV: 'europarltv.europa.eu',
    EUROPARL_EUROPA: 'europarl.europa.eu',
    EXTERNAL: '/external/',
    EP_PRODUCT: '/ep_products/',
    DOCUMENT_EXTENTIONS: /\.pdf|\.doc|\.xls|\.xml|\.zip/,
    DOWNLOAD_CENTRE_BTN_ID: 'download-centre_download-btn',
    FACEBOOK: 'facebook.com',
    TWITTER: 'twitter.com',
    LINKEDIN: 'linkedin.com'
}

export const CLICK: { [key : string]: string } = {
    ACTION: 'click.action',
    DOWNLOAD: 'click.download',
    EXIT: 'click.exit',
    NAVIGATION: 'click.navigation',
    INTERNAL_SEARCH_RESULT: 'internal_search_result.click',
}

export const AV: { [key : string]: string } = {
    PLAY: 'av.play',
    PAUSE: 'av.pause' ,
    RESUME: 'av.resume',
    STOP: 'av.stop'
}

