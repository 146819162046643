
import getBaseEvent from '@/tracker/event-getters/get-base-event'
// Types
import { SearchResultLinkEvent } from "@/types"

const getSearchResultLinkEvent = ( link: HTMLAnchorElement, index: number, input: HTMLInputElement | null ): SearchResultLinkEvent => {

    // TODO => update the ise_page on load more data action
    return {
        ...getBaseEvent(),
        'label': link.textContent?.trim(),
        'span_label': link.querySelector<HTMLSpanElement>('span')?.textContent?.trim(),
        'ise_keyword': input?.value,
        'ise_page': 1,
        'ise_click_rank': index,
        'details': link.getAttribute('data-details')
    }
}

export default getSearchResultLinkEvent