
import getBaseEvent from '@/tracker/event-getters/get-base-event'
// Types
import { LinkEvent, ClickType } from '@/types'


const getLinkEvent = (link: HTMLAnchorElement, clickType: ClickType ): LinkEvent => {
    
    const linkEvent : LinkEvent  = {
        ...getBaseEvent(),
        'click': link.href,
        'label': link.textContent?.trim(),
        'span_label': link.querySelector<HTMLSpanElement>('span')?.textContent?.trim(),
        'details': link.getAttribute('data-details')
    }

    if (clickType === 'click.exit') {
        linkEvent['visit_exitpage'] = linkEvent.site;
    }

    return linkEvent
}


export default getLinkEvent