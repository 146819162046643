
// Types
import { SearchFormEvent } from '@/types'

const  getSearchFormEvent = (): SearchFormEvent => {

    const input: HTMLInputElement | null = document.querySelector<HTMLInputElement>('__SEARCH_INPUT_SELECTOR__') as HTMLInputElement

    return {
        'click': input?.value 
    }
}

export default getSearchFormEvent