

// Const
import { CLICK, LINK_TYPE_IDENTIFIERS } from '@/const';
// Types
import { ClickType } from '@/types'


export const getLanguage = (): string | undefined=> {

    const html: HTMLElement | null = document.querySelector('html')
    const body: HTMLElement | null = document.body
    
    let language: string | null | any = html?.getAttribute('lang') || body?.getAttribute('lang')

    if (!language) {
        const metaLanguage: HTMLElement | null | any = document.querySelector('meta[name="language"]')
        language = metaLanguage?.content
    }
 
    return language
}


export const getPage = (language: string | undefined): string | undefined => {

    const splitedUrl: string[] = location.href.split('/')
    const supposedPage: string | undefined = splitedUrl.pop()

    return supposedPage === language ? splitedUrl[splitedUrl.length - 1] : supposedPage
}


export const getPageLoadTime = (): number | undefined => {

    if (window.performance) {

        const entries = window.performance.getEntriesByType('navigation')

        if (entries.length > 0) {
            
            const { duration } = entries[0] as PerformanceNavigationTiming
            
            return duration / 1000
        }
    }

}


const { MULTIMEDIA_CENTER, AUDIO_VISUAL, EUROPARL_TV, EUROPARL_EUROPA, EXTERNAL, EP_PRODUCT, DOCUMENT_EXTENTIONS, DOWNLOAD_CENTRE_BTN_ID, FACEBOOK, TWITTER, LINKEDIN } = LINK_TYPE_IDENTIFIERS

export const getClickType = (link: HTMLAnchorElement): ClickType | null => {

    if(!link.href || (typeof link.href !== 'string')) return null

    const href = link.href.toLowerCase();
    const isMuliMediaCentreLink = href.includes(MULTIMEDIA_CENTER)
    const isAudioVisualLink = href.includes(AUDIO_VISUAL)
    const isEuroparlTVLink = href.includes(EUROPARL_TV)
    const isInfoGraphicLink = href.includes(EUROPARL_EUROPA) && (href.includes(EXTERNAL) || href.includes(EP_PRODUCT))
    const isDowloadDocLink = href.match(DOCUMENT_EXTENTIONS)
    const isDownloadIdLink = link.id === DOWNLOAD_CENTRE_BTN_ID
    const isSocialNetworkLink = href.includes(FACEBOOK) || href.includes(TWITTER) || href.includes(LINKEDIN)
    const isInternalLink = href.includes(window.location.host)

    if (isMuliMediaCentreLink || isAudioVisualLink || isEuroparlTVLink || isInfoGraphicLink) {
        return CLICK.ACTION
    } else if (isDowloadDocLink || isDownloadIdLink) {
        return CLICK.DOWNLOAD
    } else if (!isInternalLink || isSocialNetworkLink) {
        return CLICK.EXIT
    } else {
        return CLICK.NAVIGATION
    }
    
}