
import getBaseEvent from '@/tracker/event-getters/get-base-event'
// Types
import { ButtonEvent, ClickType } from '@/types'

const getButtonEvent = (button: HTMLButtonElement, clickType: ClickType ): ButtonEvent => {
    
    const buttonEvent: ButtonEvent = {
        ...getBaseEvent(),
        'label': button.textContent?.trim(),
        'span_label':  button.querySelector<HTMLSpanElement>('span')?.textContent?.trim(),
        'button_expand': button.getAttribute('aria-expanded'),
        'click': button.type,
        'details': button.getAttribute('data-details')
    }
  
    if (clickType === 'click.exit') {
        buttonEvent['visit_exitpage'] = buttonEvent.site;
    }

    return buttonEvent
}

export default getButtonEvent