// Event Sender
import sendClickEvent from '@/tracker/event-senders/send-click-event'
// Event Getter
import getButtonEvent from '@/tracker/event-getters/get-button-event'
// Const
import {  EVENT_ATTACHED_ATTRIBUTE_SELECTOR, CLICK } from '@/const'

const attachButtonsEvents = (buttonList: NodeListOf<HTMLButtonElement> = document.body.querySelectorAll<HTMLButtonElement>('button')): void => {

  buttonList.forEach((button: HTMLButtonElement) => {

    if (!button.hasAttribute(EVENT_ATTACHED_ATTRIBUTE_SELECTOR)) {

      button.addEventListener('click', () => sendClickEvent(CLICK.ACTION, getButtonEvent(button, CLICK.ACTION)))
      
      button.setAttribute(EVENT_ATTACHED_ATTRIBUTE_SELECTOR, 'true')

    }

  })
}


export default attachButtonsEvents