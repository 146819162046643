// Helpers
import { getLanguage } from '@/helpers'
// Types
import { BaseEvent } from '@/types/index'

const getBaseEvent = (): BaseEvent => {

    const language: string | undefined = getLanguage()

    return {
        'site': '__SITE__',
        'language': language ? language.toLowerCase() : '',
        'click_chapter1': (document.querySelector<HTMLInputElement>('__CHAPT_1_SELECTOR__') as HTMLInputElement)?.value,
        'click_chapter2': (document.querySelector<HTMLInputElement>('__CHAPT_2_SELECTOR__') as HTMLInputElement)?.value,
        'click_chapter3': (document.querySelector<HTMLInputElement>('__CHAPT_3_SELECTOR__') as HTMLInputElement)?.value
      }
}

export default getBaseEvent