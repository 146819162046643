
// Event Send
import sendClickEvent from '@/tracker/event-senders/send-click-event'
// Event Getter
import getSearchFormEvent from '@/tracker/event-getters/get-search-form-event';
// Const
import { EVENT_ATTACHED_ATTRIBUTE_SELECTOR, CLICK } from '@/const';

const attachSearchFormEvent = (): void => {
    
    const form: HTMLFormElement | null = document.body.querySelector<HTMLFormElement>('__SEARCH_FORM_SELECTOR__');

    if (!!form && !form.hasAttribute(EVENT_ATTACHED_ATTRIBUTE_SELECTOR)) {

        form.addEventListener('submit', () => sendClickEvent(CLICK.ACTION, getSearchFormEvent()))

        form.setAttribute(EVENT_ATTACHED_ATTRIBUTE_SELECTOR, 'true');

    }
}

export default attachSearchFormEvent
