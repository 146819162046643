// Event sender
import sendClickEvent from '@/tracker/event-senders/send-click-event'
// Event Getter
import getLinkEvent from '@/tracker/event-getters/get-link-event'
// Get click Type
import { getClickType } from '@/helpers'
// Const
import {  EVENT_ATTACHED_ATTRIBUTE_SELECTOR } from '@/const'
// Types
import { ClickType } from '@/types'

const attachLinksEvents = (linkList: NodeListOf<HTMLAnchorElement> = document.body.querySelectorAll<HTMLAnchorElement>('a')) => {

    linkList.forEach((link: HTMLAnchorElement) => {

        if (!link.hasAttribute(EVENT_ATTACHED_ATTRIBUTE_SELECTOR)) {
        
            const clickType: ClickType | null = getClickType(link)

            if (!!clickType) {
              link.addEventListener('click', () => sendClickEvent(clickType, getLinkEvent(link, clickType)))
              link.setAttribute(EVENT_ATTACHED_ATTRIBUTE_SELECTOR, 'true')
            }

         
          };

    })
  }


export default attachLinksEvents
