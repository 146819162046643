




// Event getters
import getBaseEvent from '@/tracker/event-getters/get-base-event'
// utils
import { getPage, getPageLoadTime } from '@/helpers'
// types
import { BaseEvent, PageDisplayEvent } from '@/types'


const getPageDisplayEvent = (): PageDisplayEvent => {

    const pageNameTag: HTMLInputElement | undefined = document.querySelector<HTMLInputElement>('__WEB_ANALYTICS_PAGE_NAME_SELECTOR__') as HTMLInputElement

    const baseEvent: BaseEvent = getBaseEvent()

    console.log(baseEvent, 'baseEvent')

    return {
        ...baseEvent,
        'page': getPage(baseEvent?.language),
        'page_load_time': getPageLoadTime(),
        'pagename': pageNameTag?.value || pageNameTag?.getAttribute('value'),
        'page_type': (document.querySelector<HTMLInputElement>('__WEB_ANALYTICS_PAGE_TYPE_SELECTOR__') as HTMLInputElement)?.value,
        'url': location.href,
        'url_key': location.href,
        'canonical_url': (document.querySelector<HTMLAnchorElement>('__CANONICAL_URL_SELECTOR__'))?.getAttribute('href') || 'No recognized',
        'referrer': document.referrer
    }
}

export default getPageDisplayEvent