
// Event Senders
import sendPageDisplay from '@/tracker/event-senders/send-page-display'
import sendPageDisplaySearchResult from '@/tracker/event-senders/send-page-display-search-result'
import sendCustomEvents from '@/tracker/event-senders/send-custom-events'

const sendEvents = (): void => {
    sendPageDisplay()
    sendCustomEvents()
    sendPageDisplaySearchResult()
}

export default sendEvents