
// Properties setter
import setProperties from '@/tracker/properties-setter'
// Event Attachers
import attachEvents from '@/tracker/event-attachers/index'
// Event Observers
import observeEvents from '@/tracker/event-observers/index'
// Event Senders
import sendEvents from '@/tracker/event-senders/index'

const initializeTracker = () => {    
    setProperties()
    attachEvents()
    observeEvents()
    sendEvents()
}

export default initializeTracker