// Types
import { PianoAnalytics, PageDisplaySearchResultEvent } from '@/types/index'

// Piano Configuration
// declare var pa: PianoAnalytics;
declare var trackerTag: PianoAnalytics;


const sendPageDisplaySearchResult= (): void => {

    const searchResultBarContainer: HTMLInputElement | null = document.querySelector('__SEARCH_RESULT_PAGE_DISPLAY_ISE_KEY_WORD_SELECTOR__')  as HTMLInputElement

    if (!!searchResultBarContainer) {
        // TODO => update the ise_page on load more data action
        const event: PageDisplaySearchResultEvent = {
            'ise_keyword': searchResultBarContainer.value,
            'ise_page': 1
        }

        // pa.sendEvent('internal_search_result.display', event);

        if (trackerTag) trackerTag.events.send('internal_search_result.display', event);
        
    }

}


export default sendPageDisplaySearchResult