

// Event Attachers
import attachLinksEvents from "@/tracker/event-attachers/attach-elements/attach-links-events"
import attachButtonsEvents from "@/tracker/event-attachers/attach-elements/attach-buttons-events"
// import attachVideosEvents from "@/tracker/event-attachers/attach-elements/attach-videos-events"

const attachElementsEvents = (): void => {
    attachLinksEvents()
    attachButtonsEvents()
    // attachVideosEvents()
}

export default attachElementsEvents
