
// Types
import { CustomEvent, PianoAnalytics } from '@/types'


// declare var pa: PianoAnalytics;
declare var trackerTag: PianoAnalytics

const sendCustomEvents = (customEventSenderElementList: NodeListOf<HTMLElement> = document.body.querySelectorAll<HTMLElement>('__WEB_ANALYTICS_CUSTOM_EVENT_SENDER_ATTRIBUTE_SELECTOR__')): void => {

    if (!trackerTag) return 

    customEventSenderElementList.forEach((customEventSenderElement: HTMLElement): void => {

        const customEvent: CustomEvent = JSON.parse(customEventSenderElement.getAttribute('__WEB_ANALYTICS_CUSTOM_EVENT_SENDER_ATTRIBUTE_SELECTOR__') as string)
        
        trackerTag.events.send(customEvent?.event_type, customEvent)

  })

}

export default sendCustomEvents