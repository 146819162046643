// Event Attachers
import attachLinksEvents from "@/tracker/event-attachers/attach-elements/attach-links-events"
import attachButtonsEvents from "@/tracker/event-attachers/attach-elements/attach-buttons-events"
// import attachVideosEvents from "@/tracker/event-attachers/attach-elements/attach-videos-events"
import attachSearchResultLinkItemsEvents from "@/tracker/event-attachers/attach-search/attach-search-result-link-items-events"
// Event senders
import sendPageDisplay from "@/tracker/event-senders/send-page-display"
import sendCustomEvents from "@/tracker/event-senders/send-custom-events"
// Types
import { PianoAnalytics } from "@/types/index"

// declare var pa: PianoAnalytics
declare var trackerTag: PianoAnalytics;

const observeMutations = (): void => {
  
  let previousUrl = location.href

  const observer = new MutationObserver((mutations: MutationRecord[]) => {

    mutations.forEach((mutation: MutationRecord) => {

      mutation.addedNodes.forEach((addedNode: Node) => {

        if (addedNode.nodeType === Node.ELEMENT_NODE) {

          const elementNode = addedNode as Element

          const searchResulLinkList: NodeListOf<HTMLAnchorElement> = elementNode.querySelectorAll<HTMLAnchorElement>('__SEARCH_RESULT_LINKS_ATTRIBUTE_SELECTOR__')
          if (searchResulLinkList.length > 0) attachSearchResultLinkItemsEvents(searchResulLinkList)

          const linksList = elementNode.querySelectorAll<HTMLAnchorElement>('a') 
          if (linksList.length > 0) attachLinksEvents(linksList)

          const buttonList = elementNode.querySelectorAll<HTMLButtonElement>('button')
          if (buttonList.length > 0) attachButtonsEvents(buttonList)

          // const videosList = elementNode.querySelectorAll<HTMLVideoElement>('video')
          // if (videosList.length > 0) attachVideosEvents(videosList)

          const customEventSenderElementList = elementNode.querySelectorAll<HTMLElement>('[data-custom-event-sender]')
          if (customEventSenderElementList.length > 0) sendCustomEvents(customEventSenderElementList)
          
        }

      });
    });

    if (location.href !== previousUrl) {

      previousUrl = location.href

      if (trackerTag) sendPageDisplay()
      
    }
  })

  observer.observe(document.body, { childList: true, subtree: true })
};


export default observeMutations;

