
// Piano Configuration
declare var trackerTag: any;
declare var ATInternet: any;

const setProperties = () => {
    // TODO => remove on switch to piano tracker
    trackerTag = new ATInternet.Tracker.Tag();
    
    if (JSON.parse('__WEB_ANALYTICS_PROPERTIES_SETTERS__')) {
        trackerTag.setProp('url_action', window.location.href, true)
    }
}


export default setProperties