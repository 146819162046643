// Types
import { PianoAnalytics, ElementEvent, ClickType, AvType } from '@/types'
// Piano Configuration
// declare var pa: PianoAnalytics
declare var trackerTag: PianoAnalytics


const sendClickEvent = ( clickType: ClickType | AvType, event: ElementEvent ) => {

    // console.log(clickType, event)

    if (trackerTag) trackerTag.events.send(clickType, event)
    
    // pa.sendEvent(clickType, event);
}


export default sendClickEvent