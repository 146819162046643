




// Event getters
import getPageDisplayEvent from '@/tracker/event-getters/get-page-display-event'
// Const 
import { WEB_ANALYTICS__CUSTOM_EVENTS } from '@/const'
// types
import { PageDisplayCustomEvent } from '@/types'

const getPageDisplayCustomEvent = (): PageDisplayCustomEvent => {
    return {
        ...getPageDisplayEvent(),
        'ep_bodies': (document.querySelector<HTMLInputElement>(WEB_ANALYTICS__CUSTOM_EVENTS.EP_BODIES_SELECTOR))?.value,
        'topics_by_weeks': (document.querySelector<HTMLInputElement>(WEB_ANALYTICS__CUSTOM_EVENTS.TOPICS_BY_WEEKS_SELECTOR))?.value,
        'priority_category': (document.querySelector<HTMLInputElement>(WEB_ANALYTICS__CUSTOM_EVENTS.PRIORITY_CATEGORY_SELECTOR))?.value,
        'priority_wordlink': (document.querySelector<HTMLInputElement>(WEB_ANALYTICS__CUSTOM_EVENTS.PRIORITY_WORDLINK_SELECTOR))?.value,
        'internal_keyword': (document.querySelector<HTMLInputElement>(WEB_ANALYTICS__CUSTOM_EVENTS.INTERNAL_KEYWORD_SELECTOR))?.value,
        'article_language': (document.querySelector<HTMLInputElement>(WEB_ANALYTICS__CUSTOM_EVENTS.ARTICLE_LANGUAGE_SELECTOR))?.value,
        'article_reference': (document.querySelector<HTMLInputElement>(WEB_ANALYTICS__CUSTOM_EVENTS.ARTICLE_REFERENCE_SELECTOR))?.value,
    }
}

export default getPageDisplayCustomEvent