
// Event send
import sendClickEvent from '@/tracker/event-senders/send-click-event'
// Event getter
import getSearchResultLinkEvent from '@/tracker/event-getters/get-search-result-link-events';
// Const
import { EVENT_ATTACHED_ATTRIBUTE_SELECTOR, CLICK } from '@/const';

export const attachSearchResultLinkItemsEvents = (searchResulLinkList: NodeListOf<HTMLAnchorElement> = document.body.querySelectorAll<HTMLAnchorElement>('__SEARCH_RESULT_LINKS_ATTRIBUTE_SELECTOR__')): void => {

    const input: HTMLInputElement | null = document.body.querySelector<HTMLInputElement>('__SEARCH_INPUT_SELECTOR__')

    searchResulLinkList?.forEach((link: HTMLAnchorElement, index: number) => {

        if (!link.hasAttribute(EVENT_ATTACHED_ATTRIBUTE_SELECTOR)) {
 
            link.addEventListener('click', () => sendClickEvent(CLICK.INTERNAL_SEARCH_RESULT, getSearchResultLinkEvent(link, index, input)))

            link.setAttribute(EVENT_ATTACHED_ATTRIBUTE_SELECTOR, 'true');

        }
    })
}

export default attachSearchResultLinkItemsEvents