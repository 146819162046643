

// Event Observers
import observeLocation from '@/tracker/event-observers/observe-location'
import observeMutations from '@/tracker/event-observers/observe-mutations'

const observeEvents = (): void => {
    observeMutations()
    observeLocation()
}

export default observeEvents